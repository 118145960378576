import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Page from "../components/page"
import Section from "../components/section"
import styled from "styled-components"
import widths from "../utils/dimensions"
import Img from "gatsby-image"
import RightArrow from '../images/right-arrow-thin.svg'
// import Button from "../components/button"
import MoreInfoTiles from "../components/more-info-tiles"
import colours from "../utils/colours"
import Spacer from "../components/spacer"
import Modal from 'react-modal'
import CloseIcon from "../images/close.svg"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from "yup"
import Button from "../components/button"

let Tiles = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 80px 0 0 0;
  a{
    color: inherit;
  }
`
let Tile = styled.div`
  width: 100%;
  margin-bottom: 50px;
  h2{
    text-align: left;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
  @media screen and (min-width: ${ widths.breakPointSmallMedium }){
    width: 50%;
    &:nth-of-type(1){
      padding-right: 5px;
    }
    &:nth-of-type(2){
      padding-left: 5px;
    }
  }
`
let TileInner = styled.div`
  max-width: 500px;
  margin: auto; 
`
let ImgContainer = styled.div`
  padding: 0;
`
let StyledModal = styled(Modal)`
  width: 90%;
  max-width: 320px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid white !important;
`
let StyledClose = styled(CloseIcon)`
  width: 40px;
  align-self: flex-end;
  margin-bottom: 20px;
  cursor: pointer;
`
let DownloadButton = styled.button`
  height: 40px;
  width: 180px;
  margin-top: 20px;
  border: 1px solid black;
  position: relative;
  float: right;
  svg{
    width: 11px;
    margin-left: 10px;
    position: absolute;
    right: 10px;
    top: 12px;
  }
`
let JustDownload = styled.a`
  color: lightgray;
  text-align: right;
  text-decoration: none;
  margin-top: 10px;
  &:hover{
    text-decoration: underline;
    color: grey;
  }
`
let StyledForm = styled(Form)`
  margin: auto;
  padding: 0;
  text-align: left;
  width: 100%;
  label{
    display: block;
    color: black;
    margin-top: 15px;
    margin-bottom: 2px;
  }
  
  // Honeypot field
  .surname{
    border: 1px solid red;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1
  }
  input{
    height: 40px;
  }
  input,
  textarea{
    width: 100%;
    padding-left: 10px;
    background-color: ${ colours.highlightGrey };
    border: none;
  }
  textarea{
    height: 200px;
    padding: 10px;
    display: block;
  }
  }
  .error{
    color: red;
  }
  .select{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    background-color: ${ colours.highlightGrey };
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding-left: 10px;
  }
`
let BottomBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
  z-index: 1000;
`
let FormMessage = styled.div`
  width: 100%;
  background-color: forestgreen;
  margin-top: 30px;
  line-height: 40px;
  text-align: center;
  margin-bottom: -40px;
  display: inline-block;
`

const DownloadsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "downloads") {
                  slug
                  seo {
                      title
                      metaDesc
                  }
                  downloadsPage {
                      headerImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      title
                      text
                      download {
                          title
                          image {
                              sourceUrl
                              altText
                              imageFile {
                                  childImageSharp {
                                      fluid {
                                          ...GatsbyImageSharpFluid_withWebp
                                      }
                                  }
                              }
                          }
                          file {
                              id
                              link
                          }
                      }
                  }
                  id
              }
          }
      }
  `)
  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.downloadsPage

  // Modal + form state
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [dlLink, setDlLink] = React.useState('');
  const [submittedDetails, setSubmittedDetails] = React.useState(false);
  const [formMessage, setFormMessage] = useState('');

  // Open modal function, if form submitted already just download file
  function openModal() {
    if(!submittedDetails){
      setIsOpen(true);
    }else{
      window.open(dlLink, '_blank')
    }
  }
  function closeModal(){
    setIsOpen(false);
  }

  // Form encode function
  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  return(
    <Page
        seoTitle={ seoData.title }
        seoDescription={ seoData.metaDesc }
    >
      <HeaderImage
        image={ pageData.headerImage }
      />
      <Section>

        {/* Download form modal */}
        <StyledModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Download Modal"
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)'
            }
          }}
        >
          <StyledClose
            onClick={closeModal}
          />
          <Formik
            initialValues={{
              name: '',
              email: '',
              surname: '',
            }}
            onSubmit={
              (values, actions) => {
                // Remove honeypot key from object
                let valuesLessSurname = (({ surname,  ...o }) => o)(values)
                fetch("/", {
                  method: "POST",
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                  body: encode({ "form-name": "downloads-form", ...valuesLessSurname })
                })
                  .then(() => {
                    setFormMessage('Your file has been downloaded')
                    actions.resetForm()
                    window.open(dlLink, '_blank')
                  })
                  .catch(() => {
                    setFormMessage('There was an error downloading your file')
                  })
                  .finally(() => {
                    actions.setSubmitting(false)
                    setSubmittedDetails(true)
                    closeModal()
                  })
              }
            }
            validationSchema={ Yup.object().shape({
              name: Yup.string()
                .max(50, 'Must be 50 characters or less')
                .required('Please enter your name'),
              surname: Yup.string()
                .max(0, 'Value in honeypot field'),
              email: Yup.string()
                .email('Invalid email address')
                .required('Please enter your email address'),
            })}
          >
            {() => (
              <StyledForm name="downloads-form" data-netlify={ true } netlify-honeypot="surname" >

                <label htmlFor="name">Name*</label>
                <Field name="name"/>
                <BottomBorder/>
                <div className="error">
                  <ErrorMessage name="name" />
                </div>

                <div className="surname">
                  <Field name="surname" tabIndex="-1" autoComplete="off"/>
                </div>

                <label htmlFor="email">Email*</label>
                <Field name="email" />
                <BottomBorder/>
                <div className="error">
                  <ErrorMessage name="email" />
                </div>

                <Button
                  type="button"
                  aria-label="Submit form button"
                  width={'180px'}
                  marginTop={'25px'}
                >
                  Download<RightArrow/>
                </Button>

                { formMessage.length > 0 &&
                <FormMessage>
                  { formMessage }
                </FormMessage>
                }

              </StyledForm>
            )}
          </Formik>

          <JustDownload href={ dlLink } download target="_blank" rel="noopener noreferrer">
            Just download file
          </JustDownload>

        </StyledModal>
          <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
          <p dangerouslySetInnerHTML={{ __html: pageData.text }}/>
          <Tiles>

            { pageData.download.map(( { title, image, file }, index ) => {
              return (

                <Tile
                  key={ index }
                >
                  <TileInner>
                    <h2 dangerouslySetInnerHTML={{ __html: title }}/>
                    <ImgContainer>
                      <Img
                        fluid={ image.imageFile.childImageSharp.fluid }
                        alt={ image.altText }
                      />
                    </ImgContainer>
                    <DownloadButton
                      width={ '195px' }
                      onClick={() => {
                        setDlLink(file.link)
                        openModal()
                      }}
                    >
                      Download<RightArrow/>
                    </DownloadButton>
                  </TileInner>
                </Tile>

              )})}

          </Tiles>
        <Spacer
          marginBottom={ '-50px' }
        />
      </Section>

      <Section backGColour={ colours.highlightGrey }>
          <MoreInfoTiles
            location={ location }
          />
      </Section>
    </Page>
  )
}
export default DownloadsPage
